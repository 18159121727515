import {
  getCommonParamsItemLabel
  // getGlobalParamsListItemLabel
} from '@/utils/common'
import store from '@/store'

export const contentTableConfig = {
  propList: [
    {
      prop: 'aid',
      label: 'param.aid',
      width: 180
    },
    {
      prop: 'tenantId',
      label: 'user.tenant',
      width: 180,
      formatter: (row) => {
        return row ? store.getters.tenantDataMap[row] : ''
      }
    },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'status',
      label: 'general.status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      },
      width: 100
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 160
    },
    {
      prop: 'options',
      label: 'param.param-operation',
      width: '380',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
  // showSelectColumn: true
}
